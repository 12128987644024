<template>
  <div class="container">
    <div
      class="container_block"
      v-show="!isempty"
    >

      <div
        v-for="items in list"
        :key="items.id"
        class="container_block_item"
        @click="toDetail(items.id)"
      >
        <div class="container_block_item_pic"><img
            :src="items?.images[0]?.url"
            alt=""
          ></div>
        <div class="container_block_item_content">
          <div
            class="container_block_item_title"
            v-if="items.payType==='1'"
          >
            <span class="container_block_item_title_score">{{items.isRebate==='0' ? items.discountPrice : items.originalPrice}}元</span>
            <span class="container_block_item_title_num">{{items.buyCount}}人兑换</span>
          </div>
          <div
            class="container_block_item_title"
            v-else-if="items.payType==='2'"
          >
            <span class="container_block_item_title_score">{{items.score}}积分</span><span class="container_block_item_title_num">{{items.buyCount}}人兑换</span>
          </div>
          <div
            class="container_block_item_title"
            v-else-if="items.payType==='3'"
          >
            <span class="container_block_item_title_score">{{items.isRebate==='0' ? items.discountPrice : items.originalPrice}}元+{{items.score}}积分</span><span class="container_block_item_title_num">{{items.buyCount}}人兑换</span>
          </div>
          <div class="container_block_item_addr">{{items.name}}</div>
        </div>
      </div>

    </div>
    <div
      class="container_page"
      v-show="!isempty"
    >
      <div class="container_page_wrap">
        <pagination
          v-show="total > 0"
          :total="total"
          v-model:page="queryParams.pageNum"
          v-model:limit="queryParams.pageSize"
          @pagination="getList"
        />
      </div>

    </div>
    <div
      class="container_empty"
      v-if="isempty"
    >
      <el-empty description="暂无数据" />
    </div>

    <div class="container_right">
      <div
        class="container_right_select"
        id="select"
        v-show="false"
      >
        <el-select
          v-model="value"
          placeholder="总站商城"
          size="large"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="container_right_btns">

        <div
          v-for="
          (item,index)
          in
          buttonList"
          :class="[item.active===false
          ? 'container_right_btns_item'
          : 'container_right_btns_item--choosed']"
          :key="
          index"
          @click="chooseCate(index)"
        ><span class="container_right_btns_item_icon">
            <el-icon :color="item.active===true? '#fff' :'#FF9959'">
              <StarFilled />
            </el-icon>
          </span><span>{{item.name}}</span></div>
      </div>

    </div>

    <div
      class="container_cart"
      @click="toShopCart"
    >
      <el-badge
        :value="cartNum"
        :max="99"
        class="item"
      >
        <div class="container_cart_wrap"><img
            src="../../assets/images/cart.png"
            alt=""
          ></div>
      </el-badge>
    </div>

  </div>
</template>

<script setup name="ShopIndex">
import { reactive, ref, toRefs } from "@vue/reactivity"
import { useRouter } from "vue-router"
import { getShopCates, getShopGoods, getCart } from "@/api/shop"
import { useStore } from 'vuex';
import Pagination from '@/components/Pagination'
const store = useStore();
const total = ref(0);
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const isempty = ref(false)
const router = useRouter();
const query = reactive({ queryParams: { pageSize: 10, pageNum: 1, cid: 2 } })
const cartList = ref([])
const cartNum = ref(0)
const { queryParams } = toRefs(query)
const value = ref('')
//const buttonList = ref([{ id: 1, name: "精选推荐", active: true }, { id: 2, name: "食品生鲜", active: false }, { id: 3, name: "个护清洁", active: false }, { id: 4, name: "母婴童装", active: false }, { id: 5, name: "美妆护肤", active: false }, { id: 6, name: "医疗健康", active: false }])
const buttonList = ref([])
const getCategories = async () => {
  let result = await getShopCates();
  if (result?.code === 200) {
    //console.log(result)
    buttonList.value = result.data;
    queryParams.value.cid = parseInt(buttonList.value[0].id);
    onLoad();
    for (let i in buttonList.value) {
      if (i == 0) {
        buttonList.value[i].active = true
      } else {
        buttonList.value[i].active = false
      }
    }
    //onLoad(queryParams.value.cid);
  }
}
getCategories();

const options = [
  {
    value: 'Option1',
    label: 'Option1',
  },
  {
    value: 'Option2',
    label: 'Option2',
  },
  {
    value: 'Option3',
    label: 'Option3',
  },
  {
    value: 'Option4',
    label: 'Option4',
  },
  {
    value: 'Option5',
    label: 'Option5',
  },
]
const chooseCate = (index) => {
  isempty.value = false;
  queryParams.value.cid = parseInt(buttonList.value[index].id)
  buttonList.value[index].active = true
  onLoad()
  for (let i in buttonList.value) {
    if (i != index) {
      buttonList.value[i].active = false
    }
  }
}

const onLoad = async () => {
  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
  console.log("queryParams.value==", queryParams.value.cid)
  queryParams.value.stationId = store.state.stationId;
  let result = await getShopGoods(queryParams.value);
  if (result?.code === 200) {
    loading.value = false;
    list.value = result?.rows;
    total.value = result.total;
    finished.value = true;
    isempty.value = false;

  } else if (result?.code === 410) {
    loading.value = false;
    list.value = [];
    //Toast("未查询到相关信息");
    finished.value = true;
    isempty.value = true;
  } else {
    loading.value = false;
    finished.value = true;
    isempty.value = true;
  }
}
const toDetail = (id) => {
  router.push({ path: "shopDetail", query: { id: id } })
}

//获取购物车列表
const getCartList = async () => {
  let result = await getCart();
  console.log("cartlist===", result)
  cartList.value = result.rows
  cartNum.value = cartList.value.length
}
getCartList();

//到购物车界面
const toShopCart = () => {
  router.push({ path: "shopCart" })
}

</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: row;
  &_page {
    //background: #fff
    position: fixed;
    background: #fff;
    bottom: 0px;
    width: 100%;
    &_wrap {
      display: flex;
      justify-content: center;
    }
  }
  &_block {
    flex: 9;
    padding: 0 30px 30px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &_item {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 6px;
      align-items: flex-start;
      margin-right: 30px;
      margin-bottom: 30px;
      height: 420px;
      width: 300px;
      &_pic {
      }
      &_pic img {
        width: 300px;
        height: 300px;
      }
      &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 20px 15px 20px;
      }
      &_title {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;
        color: #212121;
        &_score {
          font-size: 20px;
          color: #ff9100;
        }
        &_num {
          font-size: 14px;
          color: #bdbdbd;
        }
      }
      &_addr {
        width: 90%;
        margin-top: 20px;
        font-size: 16px;
        color: #bdbdbd;
        text-align: left;
      }
    }
  }
  &_empty {
    flex: 9;
    padding: 0 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    &_btns {
      margin-top: 40px;
      font-size: 20px;
      &_item {
        background-color: #fff;
        padding: 20px 30px 20px 30px;
        border-radius: 10px 0px 0px 10px;
        margin-bottom: 20px;
        line-height: 20px;
        text-align: center;
        vertical-align: middle;
        color: #ff9100;
        &_icon {
          margin-right: 10px;
          margin-top: 15px;
        }
      }
      &_item--choosed {
        background-color: #ff9959;
        padding: 20px 30px 20px 30px;
        border-radius: 10px 0px 0px 10px;
        margin-bottom: 20px;
        line-height: 20px;
        text-align: center;
        vertical-align: middle;
        color: white;
        &_icon {
          margin-right: 10px;
          margin-top: 15px;
          color: #fff;
        }
      }
    }
  }
  &_cart {
    position: fixed;
    top: 75%;
    right: 80px;
    &_wrap {
      background: #fff;
      border-radius: 50px;
      padding: 30px 30px;
    }
    &_wrap img {
      height: 30px;
    }
  }
}
// :deep(.el-input__inner) {
//   height: 60px;
//   width: 130px;
//   border-radius: 10px;
// }
:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #ff9959;
}
</style>
